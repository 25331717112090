.landingpage__container {
  background-color: var(--white);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;

  padding: 1rem;
  margin-left: auto; 
  margin-right: auto; 
  max-width: 1200px; 
}

.landingpage__container.fadeInAnimation {
  animation: fadeIn 2s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header__container {
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: left
  ;
}

.hero {
  width: 55%;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__h1 {
  font-size: 2rem;
  font-weight: 600;
  position: relative;
}

.header__small {
  min-width: 9rem;
  background-color: white;
  text-align: center;
  position: relative;
  top: -1.2rem;
  justify-content: center;
  margin-top: 0.7rem;
}

.header__p {
  font-size: medium;
  font-family: montserrat;
  text-align: center;
  margin-top: 0.4rem;
}

.introduction {
 text-align: left;
  display: flex;
  flex-direction: column;
}

.landingpage__h2 {
  font-size: 1rem;
  font-family: montserrat;
  margin-top: 0.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-container {
  display: flex;
  justify-content: center;
}

.contact-links {
  display: flex;
  flex-direction: row;
}

.ul.contact-container {
  justify-content: center;
  align-items: center;
}

.icon-container {
  align-items: center;
  justify-content: center;
}

.scroll__down {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 3rem;
  height: 6rem;
  border: none;
  z-index: 1000;
}

.landingpage__p {
  color: rgb(83, 141, 143);
  text-align: center;
  margin-bottom: 1rem;
}

.scroll__down {
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 50px;
  height: 15rem;
  border: none;
  z-index: 1000;
}

@media only screen and (min-width: 768px) {
  .landingpage__container {
    background-color: var(--gray);
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    min-height: 99vh;
    padding: 7rem;
    align-items: center;
  }

  .hero {
    width: 35%;
    margin-left: 4rem;
  }

  .header__h1 {
    font-size: 4rem;
    margin-left: 0;
  }

  .header__container {
    justify-content: left;
    align-items: baseline;
    margin-top: 2rem;
  }

  .header__small {
    width: 50%;
  }

  .landingpage__content {
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-left: 7rem;
  }

  .landingpage__h2 {
    font-size: xx-large;
    font-family: "montserrat";
  }

  .landingpage__p {
    color: rgb(83, 141, 143);
  }

  .landingpage__briefcase {
    display: flex;
    margin-top: 1rem;
  }

  .skills__container {

    align-self: flex-end;
  }

  .landingpage__p {
    color: rgb(83, 141, 143);
    font-size: larger;
    text-align: left;
  }

  .contact-container {
    justify-content: left;
    margin-top: 1rem;
  }

  .skill-item .icon {
    width: 70%; /* Adjust the width as needed */
  }

  .scroll__down {
    position: fixed;
    bottom: 20px;
    right: 25px;
    width: 50px;
    height: 20rem;
    border: none;
    z-index: 1000;
  }

  .header__small {
    min-width: 28rem;
    background-color: white;
    position: relative;
    top: -2.3rem;
    justify-content: center;
    margin-top: 0.7rem;
  }

  .landingpage__h2 {
    font-size: x-large;
    font-family: montserrat;
    margin-top: 0.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: left;
  }
}
